import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { LogService } from '../log.service';
import { AccountService } from '../account.service';
import { TranslateService } from '../../translate/translate.service';
import { BackendService, Message } from '../backend.service';

export class BowClass {
  ID: number;
  Name: string;
  Shortcut: string;
}

export class ReviewGroup {
  ID_BowClass: number;
  BowClass: string;
  Shortcut: string;
  ID_Gender: number;
  Gender: string;
  ID_Age: number;
  Age: string;
}

@Injectable({
  providedIn: 'root'
})
export class BowClassesService {
  private bowClasses: BowClass[];
  private reviewGroups: ReviewGroup[];
  private backendResponse: Message;

  constructor(private backend: BackendService,
    private log: LogService,
    private translate: TranslateService,
    private account: AccountService) { }

  private ParseBowClassesFromResponse(bowClassNotification$: Subject<BowClass[]>) {
    this.bowClasses = [];

    this.bowClasses.push({
      ID: null,
      Name: this.translate.instant('Undefined'),
      Shortcut: '-'
    });

    if (this.backendResponse.Code === 200) {
      this.backendResponse.Payload.forEach(element => {
        this.bowClasses.push({
          ID: element[0],
          Name: element[1],
          Shortcut: element[2]
        });
      });
    }
    bowClassNotification$.next(this.bowClasses);
  }

  private ParseReviewGroupsFromResponse(reviewGroupNotification$: Subject<ReviewGroup[]>) {
    this.reviewGroups = [];

    this.reviewGroups.push({
      ID_BowClass: null,
      BowClass: this.translate.instant('Undefined'),
      Shortcut: '-',
      ID_Gender: null,
      Gender: 'Undefined',
      ID_Age: null,
      Age: 'Undefined',
    });

    if (this.backendResponse.Code === 200) {
      this.backendResponse.Payload.forEach(element => {
        this.reviewGroups.push({
          ID_BowClass: element[0],
          BowClass: element[1],
          Shortcut: element[2],
          ID_Gender: element[3],
          Gender: element[4],
          ID_Age: element[5],
          Age: element[6],
        });
      });
    }
    reviewGroupNotification$.next(this.reviewGroups);
  }

  public GetReviewGroups(): Observable<ReviewGroup[]> {
    const reviewGroupNotification$ = new Subject<ReviewGroup[]>();
    const account = this.account.GetAccountData();
    const request = JSON.stringify({
      operation: 'GET',
      resource: 'BowHunter/ReviewGroups',
      username: account.username,
      password: account.password
    });

    this.backend.sendRequest(request).subscribe(
      (msg: Message) => {
        this.backendResponse = msg;
        this.ParseReviewGroupsFromResponse(reviewGroupNotification$);
      },
      error => {
        this.log.LogError(error);
      }
    );
    return reviewGroupNotification$;
  }

  public GetAll(): Observable<BowClass[]> {
    const bowClassNotification$ = new Subject<BowClass[]>();
    const account = this.account.GetAccountData();
    const request = JSON.stringify({
      operation: 'GET',
      resource: 'BowHunter/BowClasses',
      username: account.username,
      password: account.password
    });

    this.backend.sendRequest(request).subscribe(
      (msg: Message) => {
        this.backendResponse = msg;
        this.ParseBowClassesFromResponse(bowClassNotification$);
      },
      error => {
        this.log.LogError(error);
      }
    );

    return bowClassNotification$;
  }
}
