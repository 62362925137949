import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AboutComponent } from './about/about.component';
import { TherenaComponent } from './therena/therena.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { BowHunterComponent } from './page-bow-hunter/bow-hunter/bow-hunter.component';
import { TranslateComponent } from './translate/translate.component';
import { AccountComponent } from './account/account.component';
import { WorktimeComponent } from './page-worktime/worktime/worktime.component';

const routes: Routes = [
  {
    path: ':lang',
    component: TranslateComponent,
    children: [
      {
        path: '',
        redirectTo: 'Therena',
        pathMatch: 'full'
      },
      {
        path: 'Therena',
        component: TherenaComponent
      },
      {
        path: 'Bowhunter',
        component: BowHunterComponent
      },
      {
        path: 'Worktime',
        component: WorktimeComponent
      },
      {
        path: 'Account',
        component: AccountComponent
      },
      {
        path: 'Login',
        component: LoginFormComponent
      },
      {
        path: 'About',
        component: AboutComponent
      }]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
