export const LANG_DE_NAME = 'de-de';

export const LANG_DE_TRANS: { [key: string]: string; } = {
    'Men': 'Männer',
    'Women': 'Frauen',
    'Adult': 'Erwachsene',
    'Teenager': 'Junioren',
    'Student': 'Schüler',
    'Name': 'Nachname',
    'Firstname': 'Vorname',
    'Organization': 'Verein',
    'Result': 'Ergebnis',
    'Login': 'Anmelden',
    'Logout': 'Abmelden',
    'About': 'Über',
    'Bowhunter': 'Bowhunter',
    'User': 'Benutzer',
    'Password': 'Passwort',
    'Login to Therena': 'Anmelden bei Therena',
    'Save': 'Speichern',
    'Cancel': 'Abbrechen',
    'Age': 'Altersklasse',
    'Bowclass': 'Bogenklasse',
    'Edit participant': 'Teilnehmer bearbeiten',
    'Gender': 'Geschlecht',
    'Undefined': 'Unbekannt',
    'Account': 'Benutzer-Konto',
    'Accounts': 'Benutzer-Konten',
    'Filter': 'Filter',
    'Search': 'Suche',
    'Show participants without results': 'Teilnehmer ohne Ergenis anzeigen',
    'Work Time': 'Arbeitszeit',
    'Date': 'Datum',
    'Edit Work Time': 'Arbeitszeit bearbeiten',
    'Start': 'Start',
    'End': 'Ende',
    'Duration': 'Dauer',
    'Participant': 'Teilnehmer',
    'Senior': 'Senioren',
    'Young senior': 'Junge Senioren',
    'Young adult': 'Junge Erwachsene'
};
