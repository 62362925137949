import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LogService } from '../services/log.service';
import { catchError, retry, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

export interface Message {
  Code: number;
  Type: string;
  Operation: string;
  Message: string;
  Payload: any;
}

@Injectable()
export class BackendService {
  private phpBackendUrl: string;

  constructor(private http: HttpClient,
    private log: LogService) {
    this.phpBackendUrl = 'https://therena.org/backend/therena.php';
  }

  public sendRequest($data: string): Observable<Message> {
    return this.http.post<Message>(this.phpBackendUrl, $data, { responseType: 'json' }).pipe(
      retry(3),
      tap($response => {
        this.log.Log(JSON.parse($data));
        if ($response.Code < 200 || $response.Code >= 300) {
          this.log.LogError($response);
        } else {
          this.log.Log($response);
        }
      }),
      catchError(this.handleError())
    );
  }

  private handleError() {
    return (error: any): Observable<Message> => {
      this.log.LogError(error);
      return of({
        Code: 500,
        Type: 'Response',
        Operation: '',
        Message: error.Message,
        Payload: null
      });
    };
  }
}
