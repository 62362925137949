import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from './translate.service';

@Component({
  selector: 'app-translate',
  templateUrl: './translate.component.html',
  styleUrls: ['./translate.component.css']
})
export class TranslateComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute,
    private translate: TranslateService) {
    this.activatedRoute.params.subscribe((params: Params) => {
      const language: string = params['lang'];
      if (language == null || language.length === 0) {
        return;
      }
      this.translate.use(language);
    });
  }

  ngOnInit() { }

}
