import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { LogService } from '../log.service';
import { AccountService } from '../account.service';
import { BackendService, Message } from '../backend.service';

export class Organization {
  ID: number;
  Name: string;
}

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {
  private organizations: Organization[];
  private backendResponse: Message;

  constructor(private backend: BackendService,
    private log: LogService,
    private account: AccountService) {}

  private ParseOrganizationsFromResponse(organizationsNotification$: Subject<Organization[]>) {
    this.organizations = [];

    if (this.backendResponse.Code === 200) {
      this.backendResponse.Payload.forEach(element => {
        this.organizations.push({
          ID: element[0],
          Name: element[1]
        });
      });
    }
    organizationsNotification$.next(this.organizations);
  }

  public GetAll(): Observable<Organization[]> {
    const organizationsNotification$ = new Subject<Organization[]>();
    const account = this.account.GetAccountData();
    const request = JSON.stringify({
      operation: 'GET',
      resource: 'BowHunter/Organizations',
      username: account.username,
      password: account.password
    });

    this.backend.sendRequest(request).subscribe(
      (msg: Message) => {
        this.backendResponse = msg;
        this.ParseOrganizationsFromResponse(organizationsNotification$);
      },
      error => {
        this.log.LogError(error);
      }
    );

    return organizationsNotification$;
  }
}
