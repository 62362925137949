import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { LogService } from '../log.service';
import { AccountService } from '../account.service';
import { UtilitiesService } from '../utilities.service';
import { BackendService, Message } from '../backend.service';

export class TimeRange {
  ID: number;
  ID_Date: number;
  Start: Date;
  End: Date;
  Duration: string;
}

@Injectable({
  providedIn: 'root'
})
export class TimetableService {
  private times: { [key: number]: TimeRange[]; };
  private backendResponse: Message;

  constructor(private backend: BackendService,
    private log: LogService,
    private account: AccountService,
    private utilites: UtilitiesService) { }

  private ParseTimeRangeFromResponse(timeRangeNotification$: Subject<{ [key: number]: TimeRange[]; }>) {
    this.times = [];

    if (this.backendResponse.Code === 200) {
      this.backendResponse.Payload.forEach(element => {
        const startDate = new Date('1/6/1984 ' + element[2]);
        const endDate = new Date('1/6/1984 ' + element[3]);
        const Milliseconds = endDate.getTime() - startDate.getTime();
        const duration = this.utilites.MillisecondsToTimeString(Milliseconds);

        const item: TimeRange = {
          ID: element[0],
          ID_Date: element[1],
          Start: startDate,
          End: endDate,
          Duration: duration
        };

        if (!this.times[item.ID_Date]) {
          this.times[item.ID_Date] = [];
        }
        this.times[item.ID_Date].push(item);
      });
    }
    timeRangeNotification$.next(this.times);
  }

  public GetTimeTable(dateId: number, timeRangeId: number): Observable<{ [key: number]: TimeRange[]; }> {
    const timeRangeNotification$ = new Subject<{ [key: number]: TimeRange[]; }>();
    const account = this.account.GetAccountData();

    const requestObject = {};
    requestObject['operation'] = 'GET';
    requestObject['resource'] = 'WorkTimes/TimeTable';
    requestObject['username'] = account.username;
    requestObject['password'] = account.password;

    const payload = {};
    if (dateId) {
      payload['dateId'] = dateId;
    }

    if (timeRangeId) {
      payload['timeRangeId'] = timeRangeId;
    }
    requestObject['payload'] = payload;

    const request = JSON.stringify(requestObject);

    this.backend.sendRequest(request).subscribe(
      (msg: Message) => {
        this.backendResponse = msg;
        this.ParseTimeRangeFromResponse(timeRangeNotification$);
      },
      error => {
        this.log.LogError(error);
      }
    );

    return timeRangeNotification$;
  }

  public GetAll(): Observable<{ [key: number]: TimeRange[]; }> {
    return this.GetTimeTable(undefined, undefined);
  }
}
