import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { LogService } from '../log.service';
import { AccountService } from '../account.service';
import { BackendService, Message } from '../backend.service';

export class DateElement {
  ID: number;
  Date: Date;
}

@Injectable({
  providedIn: 'root'
})
export class DatesService {
  private dates: DateElement[];
  private backendResponse: Message;

  constructor(private backend: BackendService,
    private log: LogService,
    private account: AccountService) { }

  private ParseDateFromResponse(datesNotification$: Subject<DateElement[]>) {
    this.dates = [];

    if (this.backendResponse.Code === 200) {
      this.backendResponse.Payload.forEach(element => {
        this.dates.push({
          ID: element[0],
          Date: new Date(element[1])
        });
      });
    }
    datesNotification$.next(this.dates);
  }

  public GetEditDate(dateId: number): Observable<DateElement> {
    const datesNotification$ = new Subject<DateElement>();
    if (dateId == null || dateId === 0) {
      return datesNotification$;
    }

    const account = this.account.GetAccountData();
    const request = JSON.stringify({
      operation: 'GET',
      resource: 'WorkTimes/Dates',
      username: account.username,
      password: account.password,
      payload: {
        dateId: dateId
      }
    });

    this.backend.sendRequest(request).subscribe(
      (msg: Message) => {
        let dateItem: DateElement;
        if (msg.Code === 200 && msg.Payload[0]) {
          dateItem = {
            ID: msg.Payload[0][0],
            Date: new Date(msg.Payload[0][1])
          };
          datesNotification$.next(dateItem);
        }
      },
      error => {
        this.log.LogError(error);
      }
    );
    return datesNotification$;
  }

  public GetAll(): Observable<DateElement[]> {
    const datesNotification$ = new Subject<DateElement[]>();
    const account = this.account.GetAccountData();
    const request = JSON.stringify({
      operation: 'GET',
      resource: 'WorkTimes/Dates',
      username: account.username,
      password: account.password
    });

    this.backend.sendRequest(request).subscribe(
      (msg: Message) => {
        this.backendResponse = msg;
        this.ParseDateFromResponse(datesNotification$);
      },
      error => {
        this.log.LogError(error);
      }
    );

    return datesNotification$;
  }
}
