import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '../translate/translate.service';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor(private router: Router,
    private translate: TranslateService) { }

  public Navigate(params) {
    const urlTree = this.router.parseUrl(this.router.url);
    const urlWithoutParams = urlTree.root.children['primary'].segments.map(it => it.path).join('/');
    this.router.navigate([urlWithoutParams, params]);
  }

  public NavigateHome(language: string) {
    this.router.navigate(['/' + language, 'Therena']);
  }

  public DateToString(date: Date) {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(this.translate.GetCurrentLanguage(), options);
  }

  public DateToTimeString(date: Date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    return hours + ':' + minutes + ':' + seconds;
  }

  public MillisecondsToTimeString(milliseconds: number) {
    const totalSeconds = milliseconds / 1000;
    const totalMinutes = Math.floor(totalSeconds / 60);
    const totalHours = Math.floor(totalMinutes / 60);

    const minutes = totalMinutes - totalHours * 60;
    const seconds = totalSeconds - totalHours * 60 * 60 - minutes * 60;

    return totalHours + ':' + minutes + ':' + seconds;
  }
}
