import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { LogService } from '../../services/log.service';
import { AccountService } from '../../services/account.service';
import { ActivatedRoute, Params } from '@angular/router';
import { UtilitiesService } from '../../services/utilities.service';
import { AgesService, Age } from '../../services/page-bow-hunter/ages.service';
import { BowClassesService, BowClass } from '../../services/page-bow-hunter/bow-classes.service';
import { ParticipantsService, Participant } from '../../services/page-bow-hunter/participants.service';

@Component({
  selector: 'app-certificate-bow-hunter',
  templateUrl: './certificate-bow-hunter.component.html',
  styleUrls: ['./certificate-bow-hunter.component.css']
})
export class CertificateBowHunterComponent implements OnInit {
  public selectedParticipantPosition = 0;
  public selectedParticipantID = 0;
  public selectedTournamentsID = 0;
  public certificateParticipant: Participant;
  public certificateParticipantBowClass: BowClass;
  public certificateParticipantAge: Age;

  @ViewChild('Certificate', {static: false})
  public CertificateElem: ElementRef;

  @ViewChild('CertificateRaw', {static: false})
  public CertificateRawElem: ElementRef;

  public ages: Age[] = [];
  public bowclasses: BowClass[] = [];

  constructor(
    private log: LogService,
    private age: AgesService,
    private account: AccountService,
    private bowclass: BowClassesService,
    private utilities: UtilitiesService,
    private activatedRoute: ActivatedRoute,
    private participant: ParticipantsService) { }

  ngOnInit() {
    this.Reset();

    this.UpdateData();
    this.account.LogginNotification().subscribe(
      (loggedIn) => {
        if (loggedIn) {
          this.UpdateData();
        }
      }
    );

    this.age.GetAll().subscribe(
      (ageArray: Age[]) => {
        this.ages = ageArray;
      },
      error => {
        this.log.LogError(error);
      }
    );

    this.bowclass.GetAll().subscribe(
      (bowclassArray: BowClass[]) => {
        this.bowclasses = bowclassArray;
      },
      error => {
        this.log.LogError(error);
      }
    );

    this.activatedRoute.params.subscribe((params: Params) => {
      this.selectedTournamentsID = params['tournament'];
      this.selectedParticipantID = params['participant'];
      this.selectedParticipantPosition = params['position'];

      if (this.selectedParticipantID > 0) {
        this.participant.GetEditParticipant(this.selectedParticipantID).subscribe(
          (part: Participant) => {
            this.certificateParticipant = part;

            this.ages.forEach((item) => {
              if (item.ID === this.certificateParticipant.ID_Age) {
                this.certificateParticipantAge = item;
              }
            });

            this.bowclasses.forEach((item) => {
              if (item.ID === this.certificateParticipant.ID_Bowclass) {
                this.certificateParticipantBowClass = item;
              }
            });
          },
          error => {
            this.log.LogError(error);
          }
        );
      }
    });
  }

  private GetDate() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();

    return dd + '.' + mm + '.' + yyyy;
  }

  private Reset() {
    this.certificateParticipant = {
      ID: 0,
      Firstname: '',
      Lastname: '',
      Organization: '',
      ID_Bowclass: 0,
      ID_Gender: 0,
      ID_Organization: 0,
      ID_Age: 0,
      Result: 0,
      ID_Tournament: this.selectedTournamentsID
    };

    this.certificateParticipantBowClass = {
      ID: 0,
      Name: '',
      Shortcut: ''
    };

    this.certificateParticipantAge = {
      ID: 0,
      Name: ''
    };
  }

  private UpdateData() {
    if (this.account.IsLoggedIn() === false) {
      return;
    }
  }

  private Print(contents) {
    const popupWin = window.open('', '_blank');
    popupWin.document.open();
    const certificateDocument = '<!DOCTYPE html><html><head>' +
      '<style>' +
      'html, body, div { height:29.7cm; width:21cm;margin:0!important;padding:0!important;overflow:auto; }' +
      'div { height:100%; width:100%; }' +
      '</style></head>' +
      // '<body>' +
      '<body onload="window.print(); window.close();">' +
      contents +
      '</body></html>';
    popupWin.document.write(certificateDocument);
    popupWin.document.close();
  }

  public CompleteCertificate() {
    this.Print(this.CertificateElem.nativeElement.innerHTML);
    this.Close();
  }

  public RawCertificate() {
    this.Print(this.CertificateRawElem.nativeElement.innerHTML);
    this.Close();
  }

  public Close() {
    this.Reset();
    this.utilities.Navigate({ tournament: this.selectedTournamentsID });
  }
}
