import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { LogService } from '../services/log.service';
import { BackendService, Message } from '../services/backend.service';

import * as CryptoJS from 'crypto-js';
import { CookieService  } from 'ngx-cookie-service';

export class User {
  ID: number;
  Name: string;
  Password: string;
}

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private cookieName: string;
  private backendResponse: Message;

  private users: User[];

  private username: string;
  private password: string;

  private LogginNotification$: Subject<boolean>;

  public LogginNotification(): Observable<boolean> {
    return this.LogginNotification$;
  }

  constructor(private backend: BackendService,
    private log: LogService,
    private cookieService: CookieService) {
    this.cookieName = '{3B4D8E14-10DF-4F05-884B-485ACA6D2AF6}';
    this.LogginNotification$ = new Subject<boolean>();

    this.CookieLogin();
  }

  public GetAccountData() {
    return {
      username: this.username,
      password: this.password
    };
  }

  private CookieLogin() {
    const requestJSON = this.cookieService.get(this.cookieName);
    if (requestJSON) {
      const accountData = JSON.parse(requestJSON);
      this.username = accountData.username;
      this.password = accountData.password;

      this.ValidateCredentials(requestJSON);
    }
  }

  private ParseUsersFromResponse(agesNotification$: Subject<User[]>) {
    this.users = [];

    if (this.backendResponse.Code === 200) {
      this.backendResponse.Payload.forEach(element => {
        this.users.push({
          ID: element[0],
          Name: element[1],
          Password: element[2]
        });
      });
    }
    agesNotification$.next(this.users);
  }

  public GetAll(): Observable<User[]> {
    const usersNotification$ = new Subject<User[]>();
    const account = this.GetAccountData();
    const request = JSON.stringify({
      operation: 'GET',
      resource: 'Users',
      username: account.username,
      password: account.password
    });

    this.backend.sendRequest(request).subscribe(
      (msg: Message) => {
        this.backendResponse = msg;
        this.ParseUsersFromResponse(usersNotification$);
      },
      error => {
        this.log.LogError(error);
      }
    );

    return usersNotification$;
  }

  public Login(username: string, password: string) {
    const hashedPassword = CryptoJS.SHA512(password).toString(CryptoJS.enc.Hex);

    this.username = username;
    this.password = hashedPassword;

    this.ValidateCredentials(JSON.stringify({
      operation: 'GET',
      resource: 'Users',
      username: this.username,
      password: this.password
    }));
  }

  public Logout() {
    this.RemoveCookie();
    this.backendResponse.Code = 403;
    this.LogginNotification$.next(false);
  }

  public IsLoggedIn() {
    if (this.backendResponse == null) {
      return false;
    }
    return this.backendResponse.Code === 200;
  }

  private RemoveCookie() {
    this.cookieService.delete(this.cookieName);
  }

  private StoreCookie(request: string) {
    if (this.backendResponse.Code === 200) {
      this.cookieService.set(this.cookieName, request);
    }
  }

  private ValidateCredentials(request: string) {
    this.backend.sendRequest(request).subscribe(
      (msg: Message) => {
        this.backendResponse = msg;
        this.StoreCookie(request);
        this.LogginNotification$.next(this.IsLoggedIn());
      },
      error => {
        this.log.LogError(error);
      }
    );
  }
}
