import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { LogService } from '../log.service';
import { AccountService } from '../account.service';
import { BackendService, Message } from '../backend.service';

export class Participant {
  ID: number;
  Firstname: string;
  Lastname: string;
  Organization: string;
  ID_Bowclass: number;
  ID_Gender: number;
  ID_Age: number;
  Result: number;
  ID_Organization: number;
  ID_Tournament: number;
}

@Injectable({
  providedIn: 'root'
})
export class ParticipantsService {
  private participants: { [key: string]: Participant[]; };
  private backendResponse: Message;

  constructor(private backend: BackendService,
    private log: LogService,
    private account: AccountService) { }

  public GetEditParticipant(participantID: number): Observable<Participant> {
    const participantNotification$ = new Subject<Participant>();
    if (participantID == null || participantID === 0) {
      return participantNotification$;
    }

    const account = this.account.GetAccountData();
    const request = JSON.stringify({
      operation: 'GET',
      resource: 'BowHunter/Participants',
      username: account.username,
      password: account.password,
      payload: {
        participant: participantID
      }
    });

    this.backend.sendRequest(request).subscribe(
      (msg: Message) => {
        let part: Participant;
        if (msg.Code === 200 && msg.Payload[0]) {
          part = {
            ID: msg.Payload[0][0],
            Lastname: msg.Payload[0][1],
            Firstname: msg.Payload[0][2],
            Organization: msg.Payload[0][6],
            ID_Bowclass: msg.Payload[0][3],
            ID_Gender: msg.Payload[0][4],
            ID_Age: msg.Payload[0][5],
            Result: msg.Payload[0][7],
            ID_Organization: msg.Payload[0][8],
            ID_Tournament: msg.Payload[0][9]
          };
          participantNotification$.next(part);
        }
      },
      error => {
        this.log.LogError(error);
      }
    );
    return participantNotification$;
  }

  public CreateCombinedKey(
    ID_Bowclass: number,
    ID_Gender: number,
    ID_Age: number): string {
    if (ID_Bowclass == null || ID_Gender == null || ID_Age == null) {
      return '.-.-.';
    } else {
      return ID_Bowclass.toString() + '-' + ID_Gender.toString() + '-' + ID_Age.toString();
    }
  }

  private ParseParticipantsFromResponse(participantNotification$:
    Subject<{ [key: string]: Participant[]; }>) {
    this.participants = {};
    if (this.backendResponse.Code === 200) {
      this.backendResponse.Payload.forEach(element => {
        const key = this.CreateCombinedKey(element[3], element[4], element[5]);
        if (!this.participants[key]) {
          this.participants[key] = [];
        }
        this.participants[key].push({
          ID: element[0],
          Lastname: element[1],
          Firstname: element[2],
          Organization: element[6],
          ID_Bowclass: element[3],
          ID_Gender: element[4],
          ID_Age: element[5],
          Result: element[7],
          ID_Organization: element[8],
          ID_Tournament: element[9]
        });
      });
    }
    participantNotification$.next(this.participants);
  }

  public AddParticipant(participant: Participant): Observable<boolean> {
    const participantCreateNotification$ = new Subject<boolean>();
    const account = this.account.GetAccountData();
    const request = JSON.stringify({
      operation: 'NEW',
      resource: 'BowHunter/Participants',
      username: account.username,
      password: account.password,
      payload: participant
    });

    this.backend.sendRequest(request).subscribe(
      (msg: Message) => {
        participantCreateNotification$.next(msg.Code === 200);
      },
      error => {
        this.log.LogError(error);
        participantCreateNotification$.next(false);
      }
    );

    return participantCreateNotification$;
  }

  public UpdateParticipant(participant: Participant): Observable<boolean> {
    const participantUpdateNotification$ = new Subject<boolean>();
    const account = this.account.GetAccountData();
    const request = JSON.stringify({
      operation: 'PUT',
      resource: 'BowHunter/Participants',
      username: account.username,
      password: account.password,
      payload: participant
    });

    this.backend.sendRequest(request).subscribe(
      (msg: Message) => {
        participantUpdateNotification$.next(msg.Code === 200);
      },
      error => {
        this.log.LogError(error);
        participantUpdateNotification$.next(false);
      }
    );

    return participantUpdateNotification$;
  }

  public GetAll(tournamentId: number): Observable<{ [key: string]: Participant[]; }> {
    const participantNotification$ = new Subject<{ [key: string]: Participant[]; }>();
    if (tournamentId == null || tournamentId === 0) {
      return participantNotification$;
    }

    const account = this.account.GetAccountData();
    const request = JSON.stringify({
      operation: 'GET',
      resource: 'BowHunter/Participants',
      username: account.username,
      password: account.password,
      payload: {
        tournament: tournamentId
      }
    });

    this.backend.sendRequest(request).subscribe(
      (msg: Message) => {
        this.backendResponse = msg;
        this.ParseParticipantsFromResponse(participantNotification$);
      },
      error => {
        this.log.LogError(error);
      }
    );

    return participantNotification$;
  }
}
