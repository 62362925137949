import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { LogService } from '../log.service';
import { AccountService } from '../account.service';
import { BackendService, Message } from '../backend.service';

export class Age {
  ID: number;
  Name: string;
}

@Injectable({
  providedIn: 'root'
})
export class AgesService {
  private ages: Age[];
  private backendResponse: Message;

  constructor(private backend: BackendService,
    private log: LogService,
    private account: AccountService) {}

  private ParseAgesFromResponse(agesNotification$: Subject<Age[]>) {
    this.ages = [];

    if (this.backendResponse.Code === 200) {
      this.backendResponse.Payload.forEach(element => {
        this.ages.push({
          ID: element[0],
          Name: element[1]
        });
      });
    }
    agesNotification$.next(this.ages);
  }

  public GetAll(): Observable<Age[]> {
    const agesNotification$ = new Subject<Age[]>();
    const account = this.account.GetAccountData();
    const request = JSON.stringify({
      operation: 'GET',
      resource: 'BowHunter/Ages',
      username: account.username,
      password: account.password
    });

    this.backend.sendRequest(request).subscribe(
      (msg: Message) => {
        this.backendResponse = msg;
        this.ParseAgesFromResponse(agesNotification$);
      },
      error => {
        this.log.LogError(error);
      }
    );

    return agesNotification$;
  }
}
