import { Injectable } from '@angular/core';
import { LogService } from '../services/log.service';

import { LANG_EN_NAME, LANG_EN_TRANS } from '../languages/lang-en-us';
import { LANG_DE_NAME, LANG_DE_TRANS } from '../languages/lang-de-de';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  private currentLang = 'en-us';
  private translations: { [key: string]: { [key: string]: string; }; };

  public use(lang: string) {
    if (this.translations[this.currentLang] == null) {
      this.log.LogError('Unable to find the language ' + this.currentLang);
    } else {
      this.currentLang = lang;
    }
  }

  constructor(private log: LogService) {
    this.translations = {};
    this.translations[LANG_EN_NAME] = LANG_EN_TRANS;
    this.translations[LANG_DE_NAME] = LANG_DE_TRANS;
  }

  public GetCurrentLanguage() {
    return this.currentLang;
  }

  private translate(key: string): string {
    if (this.translations[this.currentLang]) {
      if (this.translations[this.currentLang][key]) {
        return this.translations[this.currentLang][key];
      }
    }

    this.log.LogError('Unable to find the ' + this.currentLang + ' translation for ' + key);
    return key;
  }

  public instant(key: string) {
    return this.translate(key);
  }
}
