import { Component, OnInit } from '@angular/core';
import { LogService } from '../services/log.service';
import { AccountService, User } from '../services/account.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
  public users = [];
  public isAuthenticated: boolean;

  constructor(private log: LogService,
    private account: AccountService) { }

  ngOnInit() {
    this.isAuthenticated = this.account.IsLoggedIn();
    this.UpdateData();

    this.account.LogginNotification().subscribe(
      (loggedIn) => {
        this.isAuthenticated = loggedIn;
        this.UpdateData();
      }
    );
  }

  private UpdateData() {
    if (this.account.IsLoggedIn() === false) {
      return;
    }

    this.account.GetAll().subscribe(
      (usersArray: User[]) => {
        this.users = usersArray;
      },
      error => {
        this.log.LogError(error);
      }
    );
  }

  public EditUser(userID: number) {

  }
}
