export const LANG_EN_NAME = 'en-us';

export const LANG_EN_TRANS: { [key: string]: string; } = {
    'Men': 'Men',
    'Women': 'Women',
    'Adult': 'Adult',
    'Teenager': 'Teenager',
    'Student': 'Student',
    'Name': 'Name',
    'Firstname': 'Firstname',
    'Organization': 'Organization',
    'Result': 'Result',
    'Login': 'Login',
    'Logout': 'Logout',
    'About': 'About',
    'Bowhunter': 'Bowhunter',
    'User': 'User',
    'Password': 'Password',
    'Login to Therena': 'Login to Therena',
    'Save': 'Save',
    'Cancel': 'Cancel',
    'Age': 'Age',
    'Bowclass': 'Bowclass',
    'Edit participant': 'Edit participant',
    'Gender': 'Gender',
    'Undefined': 'Undefined',
    'Account': 'Account',
    'Accounts': 'Accounts',
    'Filter': 'Filter',
    'Search': 'Search',
    'Show participants without results': 'Show participants without results',
    'Work Time': 'Work Time',
    'Date': 'Date',
    'Edit Work Time': 'Edit Work Time',
    'Start': 'Start',
    'End': 'End',
    'Duration': 'Duration',
    'Participant': 'Participant',
    'Senior': 'Senior',
    'Young senior': 'Young senior',
    'Young adult': 'Young adult'
};
