import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CookieService  } from 'ngx-cookie-service';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { BowHunterComponent } from './page-bow-hunter/bow-hunter/bow-hunter.component';
import { TherenaComponent } from './therena/therena.component';
import { BackendService } from './services/backend.service';
import { AccountService } from './services/account.service';
import { TournamentsService } from './services/page-bow-hunter/tournaments.service';
import { LogService } from './services/log.service';
import { EditBowHunterComponent } from './page-bow-hunter/edit-bow-hunter/edit-bow-hunter.component';
import { CertificateBowHunterComponent } from './page-bow-hunter/certificate-bow-hunter/certificate-bow-hunter.component';
import { AboutComponent } from './about/about.component';
import { TranslatePipe } from './translate/translate.pipe';
import { TranslateComponent } from './translate/translate.component';
import { AccountComponent } from './account/account.component';
import { WorktimeComponent } from './page-worktime/worktime/worktime.component';
import { EditWorktimeComponent } from './page-worktime/edit-worktime/edit-worktime.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginFormComponent,
    BowHunterComponent,
    TherenaComponent,
    EditBowHunterComponent,
    CertificateBowHunterComponent,
    AboutComponent,
    TranslatePipe,
    TranslateComponent,
    AccountComponent,
    WorktimeComponent,
    EditWorktimeComponent
  ],
  imports: [
    NgbModule,
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [
    LogService,
    BackendService,
    AccountService,
    TournamentsService,
    CookieService,
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
