import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { AccountService } from './services/account.service';
import { TranslateService } from './translate/translate.service';
import { ActivatedRoute, Params } from '@angular/router';
import { UtilitiesService } from './services/utilities.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  @ViewChild('toggel', {static: false})
  public toggel: ElementRef;
  public isAuthenticated: boolean;

  constructor(private activatedRoute: ActivatedRoute,
    private account: AccountService,
    private utilities: UtilitiesService,
    private translate: TranslateService) {
    this.activatedRoute.params.subscribe((params: Params) => {
      const language: string = params['lang'];
      if (language == null || language.length === 0) {
        this.utilities.NavigateHome(this.GetDefaultLanguage());
      }
    });
  }

  ngOnInit() {
    this.isAuthenticated = this.account.IsLoggedIn();
    this.account.LogginNotification().subscribe(
      (loggedIn) => {
        this.isAuthenticated = loggedIn;
      }
    );
  }

  public GetDefaultLanguage() {
    return this.translate.GetCurrentLanguage();
  }

  public ToggleState() {
    if (this.toggel) {
      if (this.toggel.nativeElement.offsetParent) {
        this.toggel.nativeElement.click();
      }
    }
  }

  public Logout() {
    this.account.Logout();
    this.ToggleState();
  }
}
