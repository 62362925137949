import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { LogService } from '../../services/log.service';
import { AccountService } from '../../services/account.service';
import { UtilitiesService } from '../../services/utilities.service';
import { TranslateService } from '../../translate/translate.service';
import { TournamentsService, Tournament } from '../../services/page-bow-hunter/tournaments.service';
import { BowClassesService, ReviewGroup } from '../../services/page-bow-hunter/bow-classes.service';
import { ParticipantsService, Participant } from '../../services/page-bow-hunter/participants.service';

@Component({
  selector: 'app-bow-hunter',
  templateUrl: './bow-hunter.component.html',
  styleUrls: ['./bow-hunter.component.css']
})
export class BowHunterComponent implements OnInit {
  public participants: { [key: string]: Participant[]; };
  public isAuthenticated: boolean;
  public tournaments: Tournament[] = [];
  public reviewGroups: ReviewGroup[] = [];
  public selectedTournamentsID = 0;
  public selectedParticipantID = 0;
  public isFilterCollapsed = true;
  public isCertificateCreationView = false;
  public textSearchString = '';
  public showParticipantsWithoutResults = true;

  constructor(
    private log: LogService,
    private activatedRoute: ActivatedRoute,
    private account: AccountService,
    private translate: TranslateService,
    private tournament: TournamentsService,
    private participant: ParticipantsService,
    private bowclass: BowClassesService,
    private utilites: UtilitiesService) { }

  ngOnInit() {
    this.isAuthenticated = this.account.IsLoggedIn();
    this.UpdateData();

    this.account.LogginNotification().subscribe(
      (loggedIn) => {
        this.isAuthenticated = loggedIn;
        this.UpdateData();
      }
    );

    this.activatedRoute.params.subscribe((params: Params) => {
      this.selectedTournamentsID = params['tournament'];
      this.LoadParticipantList(this.selectedTournamentsID);

      this.selectedParticipantID = params['participant'];

      if (params['certificate'] && params['certificate'] === '1') {
        this.isCertificateCreationView = true;
      } else {
        this.isCertificateCreationView = false;
      }

      this.LoadEditForm();
      this.LoadCertificateForm();
    });
  }

  private UpdateData() {
    if (this.account.IsLoggedIn() === false) {
      return;
    }

    this.tournament.GetAll().subscribe(
      (tournamentArray: Tournament[]) => {
        this.tournaments = tournamentArray.sort((obj1, obj2) => {
          if (obj1.Name < obj2.Name) {
            return -1;
          }
          if (obj1.Name > obj2.Name) {
            return 1;
          }
          return 0;
        });
        if (this.tournaments[0]) {
          this.utilites.Navigate({ tournament: this.tournaments[0].ID });
        }
      },
      error => {
        this.log.LogError(error);
      }
    );

    this.bowclass.GetReviewGroups().subscribe(
      (reviewGroupsArray: ReviewGroup[]) => {
        this.reviewGroups = reviewGroupsArray;
      },
      error => {
        this.log.LogError(error);
      }
    );

    this.LoadEditForm();
    this.LoadCertificateForm();
  }

  private LoadEditForm() {
    if (this.selectedParticipantID &&
      this.isCertificateCreationView === false) {
      this.EditFormVisibility('block');
    } else {
      this.EditFormVisibility('none');
    }
  }

  private LoadCertificateForm() {
    if (this.selectedParticipantID &&
      this.isCertificateCreationView === true) {
      this.CertificateFormVisibility('block');
    } else {
      this.CertificateFormVisibility('none');
    }
  }

  private CertificateFormVisibility(display) {
    if (this.isAuthenticated === false) {
      return;
    }
    const elem: HTMLElement = document.getElementById('modal-certificate-participant');
    if (elem) {
      elem.style.display = display;
    }
  }

  private EditFormVisibility(display) {
    if (this.isAuthenticated === false) {
      return;
    }
    const elem: HTMLElement = document.getElementById('modal-edit-participant');
    if (elem) {
      elem.style.display = display;
    }
  }

  private ContainsReviewGroupKeys(bowclass: number,
    gender: number,
    age: number) {
    const combinedKey = this.participant.CreateCombinedKey(bowclass, gender, age);
    for (const key in this.participants) {
      if (key === combinedKey) {
        return true;
      }
    }
    return false;
  }

  public AddParticipant() {
    this.utilites.Navigate({
      tournament: this.selectedTournamentsID,
      participant: 0
    });
  }

  public GetReviewGroups(): ReviewGroup[] {
    const filteredBowclasses: ReviewGroup[] = [];
    for (const item of this.reviewGroups) {
      const participants: Participant[] = this.GetParticipants(item);
      if (participants) {
        if (participants.length > 0) {
          filteredBowclasses.push(item);
        }
      }
    }
    return filteredBowclasses;
  }

  private EscapeDataForCVS(data: string): string {
    if (data == null) {
      return '';
    }
    const result = data.replace('\r', '');
    return result.replace('\n', '');
  }

  private CreateCVSFromParticipants(): string {
    let result = 'ID;';
    result += this.translate.instant('Lastname') + ';';
    result += this.translate.instant('Firstname') + ';';
    result += this.translate.instant('Organization') + ';';
    result += this.translate.instant('Bowclass') + ';';
    result += this.translate.instant('Result') + '\n';

    for (const item of this.reviewGroups) {
      const participants: Participant[] = this.GetParticipants(item);

      if (participants) {
        let group = item.Shortcut + ' ';
        group += this.translate.instant(item.Gender) + ' ';
        group += this.translate.instant(item.Age);

        participants.forEach((part) => {
          result += part.ID + ';';
          result += this.EscapeDataForCVS(part.Lastname) + ';';
          result += this.EscapeDataForCVS(part.Firstname) + ';';
          result += this.EscapeDataForCVS(part.Organization) + ';';
          result += this.EscapeDataForCVS(group) + ';';
          result += part.Result + '\n';
        });
      }
    }
    return result;
  }

  public Print() {
    window.print();
  }

  public DownloadData(filename: string, data: string) {
    if (!data.match(/^data:text\/csv/i)) {
      data = 'data:text/csv;Windows-1252,\ufeff' + data;
    }
    const dataCVS = encodeURI(data);

    const link = document.createElement('a');
    link.setAttribute('href', dataCVS);
    link.setAttribute('download', filename);
    link.click();
  }

  public Export() {
    const data = this.CreateCVSFromParticipants();
    this.DownloadData('participants.csv', data);
  }

  public CreateCertificate(pos: number, participant: number) {
    this.utilites.Navigate({
      tournament: this.selectedTournamentsID,
      participant: participant,
      certificate: 1,
      position: pos
    });
  }

  public EditResult(pos: number, participant: number) {
    this.utilites.Navigate({
      tournament: this.selectedTournamentsID,
      participant: participant,
      certificate: 0,
      position: pos
    });
  }

  public UpdateTextSearch(value: string) {
    this.textSearchString = value;
  }

  public GetParticipants(reviewGroup: ReviewGroup): Participant[] {
    if (!this.ContainsReviewGroupKeys(
      reviewGroup.ID_BowClass,
      reviewGroup.ID_Gender,
      reviewGroup.ID_Age)) {
      return Participant[0];
    }
    const combinedKey = this.participant.CreateCombinedKey(
      reviewGroup.ID_BowClass,
      reviewGroup.ID_Gender,
      reviewGroup.ID_Age);

    const filteredParticipants = this.participants[combinedKey].filter((part: Participant) => {
      let result = true;
      if (this.showParticipantsWithoutResults === false) {
        result = part.Result > 0;
      }
      if (result === false) {
        return result;
      }
      const jsonPart: string = JSON.stringify(part);
      return jsonPart.includes(this.textSearchString);
    });

    return filteredParticipants.sort((obj1, obj2) => {
      if (obj1.Result < obj2.Result) {
        return 1;
      }
      if (obj1.Result > obj2.Result) {
        return -1;
      }

      if (obj1.Lastname < obj2.Lastname) {
        return -1;
      }
      if (obj1.Lastname > obj2.Lastname) {
        return 1;
      }

      if (obj1.Firstname < obj2.Firstname) {
        return -1;
      }
      if (obj1.Firstname > obj2.Firstname) {
        return 1;
      }
      return 0;
    });
  }

  public LoadParticipantList(tournament) {
    this.participant.GetAll(tournament).subscribe(
      (participantArray: { [key: string]: Participant[]; }) => {
        this.participants = participantArray;
        this.selectedTournamentsID = tournament;
      },
      error => {
        this.log.LogError(error);
      }
    );
  }

  public OnTournamentChange(event) {
    const tournament = event.target.value;
    if (isNaN(tournament) || tournament <= 0) {
      this.log.LogError('Tournament invalid: ' + tournament);
      return;
    }
    this.utilites.Navigate({ tournament: tournament });
    this.LoadParticipantList(tournament);
  }
}
