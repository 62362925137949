import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AccountService } from '../services/account.service';
import { UtilitiesService } from '../services/utilities.service';
import { TranslateService } from '../translate/translate.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']

})
export class LoginFormComponent implements OnInit {
  public loginForm = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
  });

  public showError = false;
  public errorOutput = 'Invalid login - Please try again';

  constructor(
    private account: AccountService,
    private translate: TranslateService,
    private utilities: UtilitiesService) { }

  ngOnInit() {
    this.account.LogginNotification().subscribe(
      (loggedIn) => {
        if (loggedIn) {
          this.showError = false;
          this.utilities.NavigateHome(this.translate.GetCurrentLanguage());
        } else {
          this.showError = true;
        }
      }
    );
  }

  onSubmit() {
    const username = this.loginForm.value.username;
    const password = this.loginForm.value.password;
    this.account.Login(username, password);
  }
}
