import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor() { }

  public Log(message) {
    if (environment.production) {
      return;
    }
    console.log(message);
  }

  public LogError(message) {
    if (environment.production) {
      return;
    }
    console.error(message);
  }
}
