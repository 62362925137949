import { Component, OnInit } from '@angular/core';
import { LogService } from '../../services/log.service';
import { FormGroup, FormControl } from '@angular/forms';
import { AccountService } from '../../services/account.service';
import { ActivatedRoute, Params } from '@angular/router';
import { BowClassesService, BowClass } from '../../services/page-bow-hunter/bow-classes.service';
import { ParticipantsService, Participant } from '../../services/page-bow-hunter/participants.service';
import { GenderService, Gender } from '../../services/page-bow-hunter/gender.service';
import { AgesService, Age } from '../../services/page-bow-hunter/ages.service';
import { UtilitiesService } from '../../services/utilities.service';
import { OrganizationsService, Organization } from '../../services/page-bow-hunter/organizations.service';

@Component({
  selector: 'app-edit-bow-hunter',
  templateUrl: './edit-bow-hunter.component.html',
  styleUrls: ['./edit-bow-hunter.component.css']
})
export class EditBowHunterComponent implements OnInit {
  public editForm = new FormGroup({
    lastname: new FormControl(''),
    firstname: new FormControl(''),
    organization: new FormControl(''),
    bowClass: new FormControl(''),
    age: new FormControl(''),
    gender: new FormControl(''),
    result: new FormControl('')
  });
  public selectedParticipantID = 0;
  public selectedTournamentsID = 0;

  public saveButtonEnabled = false;

  public ages: Age[] = [];
  public genders: Gender[] = [];
  public bowclasses: BowClass[] = [];
  public organizations: Organization[] = [];

  public editParticipant: Participant;

  constructor(
    private log: LogService,
    private age: AgesService,
    private gender: GenderService,
    private account: AccountService,
    private utilities: UtilitiesService,
    private bowclass: BowClassesService,
    private activatedRoute: ActivatedRoute,
    private organiaztion: OrganizationsService,
    private participant: ParticipantsService) { }

  ngOnInit() {
    this.ResetParticipant();

    this.UpdateData();
    this.account.LogginNotification().subscribe(
      (loggedIn) => {
        if (loggedIn) {
          this.UpdateData();
        }
      }
    );

    this.activatedRoute.params.subscribe((params: Params) => {
      this.selectedTournamentsID = params['tournament'];
      this.selectedParticipantID = params['participant'];

      if (this.selectedParticipantID > 0) {
        this.participant.GetEditParticipant(this.selectedParticipantID).subscribe(
          (part: Participant) => {
            this.editParticipant = part;
            this.editForm.patchValue({
              lastname: this.editParticipant.Lastname,
              firstname: this.editParticipant.Firstname,
              organization: this.editParticipant.ID_Organization,
              bowClass: this.editParticipant.ID_Bowclass,
              gender: this.editParticipant.ID_Gender,
              age: this.editParticipant.ID_Age,
              result: this.editParticipant.Result
            });
            this.saveButtonEnabled = true;
          },
          error => {
            this.log.LogError(error);
          }
        );
      } else {
        this.saveButtonEnabled = true;
      }
    });
  }

  public AreInputErrorsAvailable() {
    return this.editForm.status !== 'VALID' ||
      this.saveButtonEnabled === false;
  }

  private ResetParticipant() {
    this.editForm.patchValue({
      lastname: '',
      firstname: '',
      organization: null,
      bowClass: null,
      gender: null,
      age: null,
      result: 0
    });

    this.editParticipant = {
      ID: 0,
      Firstname: '',
      Lastname: '',
      Organization: '',
      ID_Bowclass: 0,
      ID_Gender: 0,
      ID_Organization: 0,
      ID_Age: 0,
      Result: 0,
      ID_Tournament: this.selectedTournamentsID
    };
  }

  private UpdateData() {
    if (this.account.IsLoggedIn() === false) {
      return;
    }
    this.bowclass.GetAll().subscribe(
      (bowclassArray: BowClass[]) => {
        this.bowclasses = bowclassArray;
      },
      error => {
        this.log.LogError(error);
      }
    );

    this.gender.GetAll().subscribe(
      (genderArray: Gender[]) => {
        this.genders = genderArray;
      },
      error => {
        this.log.LogError(error);
      }
    );

    this.age.GetAll().subscribe(
      (ageArray: Age[]) => {
        this.ages = ageArray;
      },
      error => {
        this.log.LogError(error);
      }
    );

    this.organiaztion.GetAll().subscribe(
      (organiaztionsArray: Age[]) => {
        this.organizations = organiaztionsArray.sort((obj1, obj2) => {
          if (obj1.Name < obj2.Name) {
            return -1;
          }
          if (obj1.Name > obj2.Name) {
            return 1;
          }
          return 0;
        });
      },
      error => {
        this.log.LogError(error);
      }
    );
  }

  public Save() {
    this.editParticipant.Lastname = this.editForm.value.lastname;
    this.editParticipant.Firstname = this.editForm.value.firstname;
    this.editParticipant.ID_Organization = this.editForm.value.organization;
    this.editParticipant.ID_Bowclass = this.editForm.value.bowClass;
    this.editParticipant.ID_Age = this.editForm.value.age;
    this.editParticipant.ID_Gender = this.editForm.value.gender;
    this.editParticipant.Result = this.editForm.value.result;
    this.editParticipant.ID_Tournament = this.selectedTournamentsID;

    if (this.selectedTournamentsID == null) {
      this.log.LogError('Unable to sve participant because no tournament selected');
      return;
    }

    if (this.selectedParticipantID > 0) {
      this.participant.UpdateParticipant(this.editParticipant).subscribe(
        (result: boolean) => {
          if (result) {
            this.Close();
          }
        },
        error => {
          this.log.LogError(error);
        }
      );
    } else {
      this.participant.AddParticipant(this.editParticipant).subscribe(
        (result: boolean) => {
          if (result) {
            this.Close();
          }
        },
        error => {
          this.log.LogError(error);
        }
      );
    }
  }

  public Close() {
    this.ResetParticipant();
    this.utilities.Navigate({ tournament: this.selectedTournamentsID });
  }
}
