import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { LogService } from '../../services/log.service';
import { AccountService } from '../../services/account.service';
import { UtilitiesService } from '../../services/utilities.service';
import { DatesService, DateElement } from '../../services/page-worktime/dates.service';
import { TimetableService, TimeRange } from '../../services/page-worktime/timetable.service';

@Component({
  selector: 'app-worktime',
  templateUrl: './worktime.component.html',
  styleUrls: ['./worktime.component.css']
})
export class WorktimeComponent implements OnInit {
  public isAuthenticated: boolean;
  public dateList: DateElement[] = [];
  public times: { [key: number]: TimeRange[]; } = {};
  public selectedDateID = 0;

  constructor(
    private log: LogService,
    private activatedRoute: ActivatedRoute,
    private account: AccountService,
    private dates: DatesService,
    private timetable: TimetableService,
    private utilites: UtilitiesService) { }

  ngOnInit() {
    this.isAuthenticated = this.account.IsLoggedIn();
    this.UpdateData();

    this.account.LogginNotification().subscribe(
      (loggedIn) => {
        this.isAuthenticated = loggedIn;
        this.UpdateData();
      }
    );

    this.activatedRoute.params.subscribe((params: Params) => {
      this.selectedDateID = params['dateId'];
      this.LoadEditForm();
    });
  }

  private LoadEditForm() {
    if (this.selectedDateID) {
      this.EditFormVisibility('block');
    } else {
      this.EditFormVisibility('none');
    }
  }

  public Print() {
    window.print();
  }

  private EditFormVisibility(display) {
    if (this.isAuthenticated === false) {
      return;
    }
    const elem: HTMLElement = document.getElementById('modal-edit-worktime');
    if (elem) {
      elem.style.display = display;
    }
  }

  private UpdateData() {
    if (this.account.IsLoggedIn() === false) {
      return;
    }

    this.dates.GetAll().subscribe(
      (dateArray: DateElement[]) => {
        this.dateList = dateArray;
      },
      error => {
        this.log.LogError(error);
      }
    );

    this.timetable.GetAll().subscribe(
      (timeArray: { [key: number]: TimeRange[]; }) => {
        this.times = timeArray;
      },
      error => {
        this.log.LogError(error);
      }
    );
  }

  public GetTimeRanges() {
    let timeRanges: TimeRange[] = [];
    if (this.times[1]) {
      timeRanges = this.times[1];
    }
    return timeRanges;
  }

  public EditDate(dateId: number) {
    this.utilites.Navigate({
      dateId: dateId
    });
  }

  public AddDate() {
    this.utilites.Navigate({
      dateId: 0
    });
  }
}
