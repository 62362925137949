import { Component, OnInit } from '@angular/core';
import { LogService } from '../../services/log.service';
import { FormGroup, FormControl } from '@angular/forms';
import { AccountService } from '../../services/account.service';
import { ActivatedRoute, Params } from '@angular/router';
import { UtilitiesService } from '../../services/utilities.service';
import { DatesService, DateElement } from '../../services/page-worktime/dates.service';

@Component({
  selector: 'app-edit-worktime',
  templateUrl: './edit-worktime.component.html',
  styleUrls: ['./edit-worktime.component.css']
})
export class EditWorktimeComponent implements OnInit {
  public editForm = new FormGroup({
    date: new FormControl('')
  });

  public selectedDateID = 0;
  public saveButtonEnabled = false;

  public editDate: DateElement;

  constructor(
    private log: LogService,
    private account: AccountService,
    private dates: DatesService,
    private utilities: UtilitiesService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.ResetWorkTime();

    this.UpdateData();
    this.account.LogginNotification().subscribe(
      (loggedIn) => {
        if (loggedIn) {
          this.UpdateData();
        }
      }
    );

    this.activatedRoute.params.subscribe((params: Params) => {
      this.selectedDateID = params['dateId'];

      if (this.selectedDateID > 0) {
        this.dates.GetEditDate(this.selectedDateID).subscribe(
          (dateItem: DateElement) => {
            this.editDate = dateItem;
            this.editForm.patchValue({
              date: this.editDate.Date
            });
            this.saveButtonEnabled = true;
          },
          error => {
            this.log.LogError(error);
          }
        );
      } else {
        this.saveButtonEnabled = true;
      }
    });
  }

  private ResetWorkTime() {
    this.editForm.patchValue({
      date: '',
      start: '',
      end: ''
    });

    this.editDate = {
      ID: 0,
      Date: new Date
    };
  }

  private UpdateData() {
    if (this.account.IsLoggedIn() === false) {
      return;
    }
  }

  public AreInputErrorsAvailable() {
    return this.editForm.status !== 'VALID' ||
      this.saveButtonEnabled === false;
  }

  public Save() {

  }

  public Close() {
    this.ResetWorkTime();
    this.utilities.Navigate({});
  }

}
